<template>
  <transition name="fade">
    <div v-if="popup">
      <div class="pop-up-bg" @click.prevent="$emit('close')"></div>
      <div class="pop-up">
        <div v-if="filter.flag">
          <a class="pop-up__close icon icon-close" @click.prevent="$emit('close')"></a>
          <h2>{{$root.langText.changes.counterparties}}</h2>
          <SelectedFilters v-if="selectFilters.length !== 0" :data="selectFilters" @sendChange="filterChange = $event"/>
          <Search :isActiveFilter="btnFilter" @inputchange="search" @filter="filter.flag = !filter.flag" v-if="counterpartie !== null" />
          <p class="list-not" v-if="listNot">{{$root.langText.notFound}}</p>
          <ul class="block-list" >
            <li
              v-for="(counterpartie, index) in counterpartie"
              :key="index"
              :id="counterpartie.id"
              @click.prevent="close(counterpartie.id, counterpartie.name)"
            >
              <h4>{{counterpartie.name}}</h4>
              <b>{{counterpartie.entity_physical_person_name ? counterpartie.entity_physical_person_name : '-'}}</b>
              <p style="margin-top: 5px">{{counterpartie.group_name ? counterpartie.group_name : '-'}}</p>
              <a class="icon icon-arrow-right"></a>
            </li>
          </ul>
          <div style="position: relative; height: 73px">
            <Pagination v-if="quantityList && quantityList > params.limit" @pagination="pagination" :dataList="{quantityList, params}"/>
            <a v-if="$root.add.can_add_contractor" class="btn btn-transparent" style="position:absolute;top: 20px; padding: 6px 20px;" @click.prevent="add = !add">{{$root.langText.btnAdd}}<div class="icon-plus"></div></a>
          </div>
          <div v-if="add">
            <Handbook :data="popup2" @closePopup="closePopup" @close="add = false" />
          </div>
        </div>
        <!-- <Filters @filter="filters" :customerFilter="data ? data.filter : ''" :idMainContractor="data ? data.idMainContractor : ''" :clientId="data ? data.idClient : ''" :idContractor="data ? data.idContractor : ''" :filterChange="filterChange" :filter="filter"/> -->
        <Filters @filter="filters" :isFilter="false" :filterChange="filterChange" :filter="filter" :filterSelectContractor="{idMainContractor: data ? data.idMainContractor : '', clientId: data ? data.idClient : '', idContractor: data ? data.idContractor : '', customerFilter: data ? data.filter : ''}" />
      </div>
    </div>
  </transition>
</template>


<style lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }

</style>


<script>
import Pagination from "@/components/app/Pagination"
import Search from "@/components/app/Search"
import SelectedFilters from "@/components/app/SelectedFilters"
import Handbook from "@/components/app/Handbook"

export default {
  props: ['popup', 'input', 'data'],

  data:() => ({
    counterpartie: [],
    quantityList: '',
    //pagination
    params: {
      start: 0, 
      limit: 20,
    },
    popup2: {
      info: [],
      mod: 'created',
      contractor_id: '',
      modPopup: 'counterparties'
    },

    arrFilters: '',
    listNot: false,
    filter: {
      flag: true,
      mod: 'counterparties',
      screen: true
    },
    btnFilter: false,
    counterpartiesNew: [],

    //
    searchData: '',
    filtersData: '',
    filterChange: '',
    selectFilters: '',
    add: false,
    
  }),
  async created() {
    //base
    if(this.data){
      if(this.data.filter){
        this.filtersData = 'f[' + this.data.filter + ']=1'
        if(this.data.filter == 'is_main'){
          this.popup2.info.is_main_contractor = 1
          this.popup2.info.is_carrier = 1
          this.popup2.info.main_contractor = ''
        } else{
          this.popup2.info.is_main_contractor = 0
          this.popup2.info.is_carrier = 1
        }
      }
      if(this.data.idClient){
        this.filtersData = this.filtersData + '&' + 'f[client_id]=' + this.data.idClient
        this.popup2.info.client_id = this.data.idClient
      }
      if(this.data.idContractor){
        this.filtersData = this.filtersData + '&' + 'f[contractor_id]=' + this.data.idContractor
        this.popup2.info.main_contractor = this.data.idContractor
      }
      if(this.data.idMainContractor){
        this.filtersData = this.filtersData + '&' + 'f[main_contractor]=' + this.data.idMainContractor
        console.log(this.filtersData)
        this.popup2.info.main_contractor = this.data.idMainContractor
      }
    }
    this.arrFilters = this.searchData + "&" + this.filtersData
    const arrFilters = await this.$store.dispatch('postFiltersСounterparties', {params: this.params, data: this.arrFilters})
    this.counterpartie = arrFilters.data.list;
    this.quantityList = arrFilters.data.total_num
    if(this.quantityList == 0){
      this.listNot = true
    } else{
      this.listNot = false
    }
  },
  methods: {
    async closePopup(){
      this.add = false
      this.arrFilters = this.searchData + "&" + this.filtersData
      const arrFilters = await this.$store.dispatch('postFiltersСounterparties', {params: this.params, data: this.arrFilters})
      this.counterpartie = arrFilters.data.list
      this.quantityList = arrFilters.data.total_num
      if(this.quantityList == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    },


    close(id, name){
      if(this.input){
        this.$emit('close', {id, name, input: this.input})
      } else{
        this.$emit('close', {id, name})
      }
    },



    //function filtration
    async reloadFilters(mod){
      if(mod !== 'pagination'){
        this.params.start = 0
      }
      this.arrFilters = this.searchData + "&" + this.filtersData
      var arrFilters = await this.$store.dispatch('postFiltersСounterparties', {params: this.params, data: this.arrFilters})
      this.counterpartie = arrFilters.data.list
      this.quantityList = arrFilters.data.total_num
      if(this.quantityList == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    },


    //filters
    async filters(data){
      this.filter.flag = true
      if(data == undefined){
        this.filtersData = '';
        this.btnFilter = false;
        this.selectFilters = [];
        this.reloadFilters('filters');
      } else{
        if(data.filter == ''){
          this.btnFilter = false
        } else{
          this.btnFilter = true
        }
        this.selectFilters = data.dataIsFilter
        this.filtersData = data.filter
        if(data.load !== true){
          this.reloadFilters('filters');
        }
      }
    },

    //search
    async search(data) {
      if(data !== undefined && data.load !== true){
        this.searchData = data.search
      }
      if(data.load !== true){
        this.reloadFilters('search');
      }
    },

    //paginations
    async pagination(data){
      this.params.start = data.start
      if(data.load !== true){
        this.reloadFilters('pagination')
      }
    }

  },
  components:{
    Pagination, Search, Filters: () => import('@/components/app/Filters.vue'), SelectedFilters, Handbook
  }
  
}
</script>