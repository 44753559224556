var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[_c('div',[_c('div',{staticClass:"pop-up-bg",on:{"click":function($event){$event.preventDefault();return _vm.$emit('closeContact', false)}}}),_c('div',{staticClass:"pop-up pop-up--delete"},[_c('a',{staticClass:"pop-up__close icon icon-close",on:{"click":function($event){$event.preventDefault();return _vm.$emit('closeContact', false)}}}),(!_vm.deleteContact)?_c('h2',[_vm._v(_vm._s(_vm.$root.langText.contacts.changeContacts))]):_c('h2',[_vm._v(_vm._s(_vm.$root.langText.contacts.deleteContacts))]),(!_vm.deleteContact)?_c('div',[_c('Autocomplete',{attrs:{"hidden":_vm.hidden,"data":{
            name: _vm.$root.langText.contacts.contactPerson, 
            mod: 'contact', 
            key: 'contact', 
            input: _vm.contact, 
            inputId: _vm.contact_id, 
            idClient: _vm.id, 
            typeClient: _vm.mod
          }},on:{"result":_vm.autocompleteResult}}),_c('Contact',{attrs:{"hidden":_vm.hidden,"data":_vm.data},on:{"change":_vm.changeInput}}),(_vm.error)?_c('p',{staticStyle:{"font-size":"13px","color":"#EB5757","margin-bottom":"15px","text-align":"center"}},[_vm._v(_vm._s(_vm.$root.langText.changes.checkData))]):_vm._e(),_c('div',{staticClass:"pop-up__btn block-btn--color"},[_c('a',{staticClass:"btn btn-primary",on:{"click":function($event){$event.preventDefault();return _vm.warningDelete($event)}}},[_vm._v(_vm._s(_vm.$root.langText.delete))]),_c('a',{staticClass:"btn btn-primary",on:{"click":function($event){$event.preventDefault();return _vm.saveContact($event)}}},[_vm._v(_vm._s(_vm.$root.langText.save))])])],1):_c('div',[_c('div',{staticClass:"img warning"}),_c('h3',[_vm._v(_vm._s(_vm.$root.langText.contacts.isDelete))]),_c('div',{staticClass:"pop-up__btn block-btn--color"},[_c('a',{staticClass:"btn btn-primary",on:{"click":function($event){$event.preventDefault();return _vm.deleteContact2($event)}}},[_vm._v(_vm._s(_vm.$root.langText.delete))]),_c('a',{staticClass:"btn btn-primary",on:{"click":function($event){$event.preventDefault();return _vm.warningDelete($event)}}},[_vm._v(_vm._s(_vm.$root.langText.save))])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }