<template>
  <transition name="fade">
    <div v-if="popup">
      <div class="pop-up-bg" @click.prevent="$emit('close')"></div>
      <div class="pop-up">
        <div>
          <a class="pop-up__close icon icon-close" @click.prevent="$emit('close')"></a>
          <h2>{{$root.langText.changes.clients}}</h2>
          <Search :filterNone="'none'" @inputchange="searchBtn"/>
          <p class="list-not" v-if="listNot">{{$root.langText.notFound}}</p>
          <ul class="block-list">
            <li
              v-for="(client, index) in clients"
              :key="index"
              :data-id="client.id"
              @click.prevent="close(client.id, client.name, client.doc_support_manager_id, client.doc_support_manager_name)"
            >
              <i :style="{background: client.status_color_bg}"></i>
              <strong :style="{color: client.status_color_bg}">{{client.funnel_stage_name}}</strong>
              <h4>{{client.name}}</h4>
              <span>{{$root.langText.input.manager}}</span>
              <p>{{client.manager_name}}</p>
              <span>{{$root.langText.input.status}}</span>
              <b>{{client.status}}</b>
              <a class="icon icon-arrow-right"></a>
            </li>
          </ul>
          <div style="position: relative; height: 73px">
            <Pagination v-if="quantityList && quantityList > params.limit" @pagination="pagination" :dataList="{quantityList, params}"/>
            <a v-if="$root.add.can_add_client" class="btn btn-transparent" style="position:absolute;top: 20px; padding: 6px 20px;" @click.prevent="add = !add">{{$root.langText.btnAdd}}<div class="icon-plus"></div></a>
          </div>
          <div v-if="add">
            <Handbook :data="popup2" @closePopup="closePopup" @close="add = false" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>


<style lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }
  .pop-up .block-list{
    overflow: auto;
    max-height: 63vh;
    width: calc(100% + 10px);
    padding: 15px 9px 5px;
    margin-left: -8px;
  }
  .pop-up .block-list b, .pop-up .block-list p{
    font-size: 12px !important;
  }
  .pop-up .block-list span{
    font-size: 10px !important;
  }
  .pop-up .block-list strong{
    font-size: 11px !important;
  }
  .pop-up .block-list h4{
    font-size: 13px !important;
  }

  .pop-up .block-btn{
    position: static;
  }
  .pop-up .block-btn a{
    margin: 0;
  }
  .pop-up .block-btn a:first-child{
    margin-right: 10px;
  }

</style>


<script>
import Search from "@/components/app/Search"
import Pagination from "@/components/app/Pagination"
import Handbook from "@/components/app/Handbook"

export default {
  props: ['popup', 'input'],

  data:() => ({
    clients: [],
    quantityList: '',
    //pagination
    params: {
      start: 0, 
      limit: 20,
    },

    popup2: {
      info: [],
      mod: 'created',
      modPopup: 'clients'
    },
    add: false,

    arrFilters: '',
    listNot: false
    
  }),
  async mounted() {
    //base
    const res = await this.$store.dispatch('getClients', this.params);
    this.clients = res.data.list;
    this.quantityList = res.data.total_num
  },
  methods: {

    close(id, name, doc_menedger_id, doc_menedger_name){
      if(this.input){
        this.$emit('close', {id, name, input: this.input})
      } else{
        this.$emit('close', {id, name, doc_menedger_id, doc_menedger_name})
      }
    },

    async closePopup(){
      this.add = false
      const arrFilters = await this.$store.dispatch('postFiltersClients', {params: this.params, data: this.arrFilters})
      this.clients = arrFilters.data.list
      this.quantityList = arrFilters.data.total_num
      if(arrFilters.data.total_num == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    },



    //function filtration
    async reloadFilters(mod){
      if(mod !== 'pagination'){
        this.params.start = 0
      }
      this.arrFilters = this.searchData
      var arrFilters = await this.$store.dispatch('postFiltersClients', {params: this.params, data: this.arrFilters})
      this.clients = arrFilters.data.list
      this.quantityList = arrFilters.data.total_num
      if(this.quantityList == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    },


    //search
    async searchBtn(data) {
      if(data !== undefined && data.load !== true){
        this.searchData = data.search
      }
      if(data.load !== true){
        this.reloadFilters('search');
      }
    },

    //paginations
    async pagination(data){
      this.params.start = data.start
      if(data.load !== true){
        this.reloadFilters('pagination')
      }
    }

  },
  components:{
    Search, Pagination, Handbook
  }
  
}
</script>