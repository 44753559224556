<template>
  <transition name="fade">
    <div v-if="popup">
      <div class="pop-up-bg" @click.prevent="$emit('close')"></div>
      <div class="pop-up">
        <a class="pop-up__close icon icon-close" @click.prevent="$emit('close')"></a>
        <h2>{{$root.langText.changes.car}}</h2>
        <Search :filterNone="'none'" @inputchange="searchBtn"/>
        <p class="list-not" v-if="listNot">{{$root.langText.notFound}}</p>
        <ul class="pop-up__list">
          <li
            v-for="(item, index) in car"
            :key="index"
            :data-id="item.id"
            @click.prevent="$emit('close', {id: item.id, name: item.name})"
          >
            <div class="pop-up__list__row">
              {{item.name}}
            </div>
            <div class="decor-line"></div>
          </li>
        </ul>
        <div style="position: relative; height: 73px">
          <Pagination v-if="quantityList && quantityList > params.limit" @pagination="pagination" :dataList="{quantityList, params}"/>
          <a class="btn btn-transparent" v-if="$root.add.can_add_driver" style="position:absolute;top: 20px; padding: 6px 20px;" @click.prevent="add = !add">{{$root.langText.btnAdd}}<div class="icon-plus"></div></a>
        </div>
        <div v-if="add">
          <Handbook :data="popup2" @closePopup="closePopup" @close="add = false" />
        </div>

      </div>
    </div>
  </transition>
</template>


<style lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }
</style>


<script>
import Pagination from "@/components/app/Pagination"
import Search from "@/components/app/Search"
import Handbook from "@/components/app/Handbook"

export default {
  props: ['popup', 'data'],

  data:() => ({
    add: false,
    popup2: {
      info: '',
      mod: 'created',
      contractor_id: '',
      modPopup: 'car'
    },

    car: [],
    quantityList: '',
    //pagination
    params: {
      start: 0, 
      limit: 20,
    },
    arrFilters: '',
    searchData: '',
    filtersData: '',
    listNot: false
    
  }),
  async mounted() {
    //base
    if(this.data.idContractor){
      this.filtersData = 'f[contractor_id]='+this.data.idContractor
      this.arrFilters = this.filtersData
    }
    const carApi = await this.$store.dispatch('postFiltersCar', {params: this.params, data: this.arrFilters});
    this.car = carApi.data.list;
    this.quantityList = carApi.data.total_num
    if(this.quantityList == 0){
      this.listNot = true
    } else{
      this.listNot = false
    }
    this.popup2.contractor_id = this.data.idContractor
  },
  methods: {
    autocompleteResult(data){
      this[data.input] = data.name
      this[data.input + '_id'] = data.id
    },

    async closePopup(){
      this.add = false
      const car = await this.$store.dispatch('postFiltersCar', {params: this.params, data: this.arrFilters})
      this.car = car.data.list;
      this.quantityList = car.data.total_num
      if(this.quantityList == '0'){
        this.listNot = true
      } else{
        this.listNot = false
      }
    },
    


    //function filtration
    async reloadFilters(mod){
      if(mod !== 'pagination'){
        this.params.start = 0
      }
      this.arrFilters = this.searchData + "&" + this.filtersData
      var arrFilters = await this.$store.dispatch('postFiltersCar', {params: this.params, data: this.arrFilters})
      this.car = arrFilters.data.list
      this.quantityList = arrFilters.data.total_num
      if(this.quantityList == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    },


    //search
    async searchBtn(data) {
      if(data !== undefined && data.load !== true){
        this.searchData = data.search
      }
      if(data.load !== true){
        this.reloadFilters('search');
      }
    },

    //paginations
    async pagination(data){
      this.params.start = data.start
      if(data.load !== true){
        this.reloadFilters('pagination')
      }
    }

  },
  components:{
    Pagination, Search, Autocomplete: () => import('@/components/app/Autocomplete.vue'), Handbook
  }
  
}
</script>