<template>
  <transition name="fade">
    <div>
      <div class="pop-up-bg" @click.prevent="$emit('close')"></div>
      <div class="pop-up pop-up--delete">
        <a class="pop-up__close icon icon-close" @click.prevent="$emit('close')"></a>
        <h2 v-if="info.mod == 'update'">{{$root.langText.contacts.viewContactPerson}}</h2>
        <h2 v-else>{{$root.langText.contacts.creatingContactPerson}}</h2>
        <div style="max-height: 80vh;overflow: auto;padding: 30px 10px 0;margin-top: -20px;">
          <div v-if="info.mod == 'create' || dataPerson[info.index].name">
            <div class="form__input">
              <input type="text" 
                v-model="name"
              />
              <span>{{$root.langText.input.pib}}</span>
            </div>
            <div class="form__input">
              <input type="text" 
                v-model="post"
              />
              <span>{{$root.langText.input.post}}</span>
            </div>
            <div class="form__input">
              <the-mask type="text" v-model="card" :mask="['####-######-#####', '####-####-####-####']"></the-mask>
              <span>{{$root.langText.input.bankCard}}</span>
            </div>
          </div>

          <ul>
            <li 
              v-for="(item, index) in listContact"
              :key="index"
              :data-id="item.contact_id"
              >
              <div class="contact__icon" @click.prevent="openPopup(index)">
                <i class="icon"
                  v-if="$root.app == 'SoftProCrmAppRioTransTest' || $root.app == 'SoftProCrmAppRioTrans'"
                  :class="
                    item.tech_name == 'phone' ? 'icon-phone-new' : 
                    item.tech_name == 'fax' ? 'icon-phone' : 
                    item.tech_name == 'email' ? 'icon-mail' : 
                    item.tech_name == 'skype' ? 'icon-skype' : 
                    item.tech_name == 'fb' ? 'icon-fb' : 
                                        'icon-other'"
                ></i>
                <i class="icon"
                  v-else
                  :class="
                    item.tech_name == 'phone' ? 'icon-phone-new' : 
                    item.tech_name == 'fax' ? 'icon-phone' : 
                    item.tech_name == 'email' ? 'icon-mail' : 
                    item.tech_name == 'skype' ? 'icon-skype' : 
                                        'icon-other'"
                ></i>
                <p>{{item.val}}</p>
              </div>
              <div class="decor-line"></div>
            </li>
          </ul>

          <Contact 
            @change="changeInput"
            :data="item"
            :index="index"
            v-for="(item, index) in listNew"
            :key="item.id"
            :hidden="hidden"
          />
          <p style="font-size: 13px; color:#EB5757;margin-bottom: 15px;text-align:center" v-if="error">{{$root.langText.changes.checkData}}</p>
          <div class="pop-up__btn" v-if="hidden">
            <a class="btn btn-transparent" 
              @click.prevent="addContacts"
            > {{$root.langText.btnAdd}}
              <div class="icon-plus"></div>
            </a>
            <a class="btn btn-primary" @click.prevent="saveContact">{{$root.langText.save}}</a>
          </div>
          
          <ContactPopup v-if="contact_popup" :hidden="hidden" :modOne="false" :dataAll="dataPerson[info.index]" :mod="info.modPerson" :id="id" :data="contact_popupData" @closeContact="ContactPopup()"/>
        </div>
      </div>
    </div>
  </transition>
</template>




<script>
import ContactPopup from '@/components/contacts/ContactPopup'
import Contact from "@/components/contacts/Contact"


export default {
  props: ['info', 'dataPerson', 'id', 'hidden'],

  data:() => ({
    name: '',
    post: '',
    card: '',
    contact_popup: false,
    contact_popupData: '',

    listContact: [],

    listNew: [],

    error: false,
    newIdPerson: '',

  }),
  
  watch:{
    dataPerson: function(){
      if(this.info.mod == 'update'){
        this.listContact = this.dataPerson[this.info.index].contacts
      }
    }
  },

  created() {
    if(this.info.mod == 'update'){
      this.name = this.dataPerson[this.info.index].name
      this.post = this.dataPerson[this.info.index].position
      this.card = this.dataPerson[this.info.index].card
      this.listContact = this.dataPerson[this.info.index].contacts
    } else{

    }
  },



  methods: {
    openPopup(index){
      if(this.hidden){
        this.contact_popupData = this.dataPerson[this.info.index].contacts[index]
        this.contact_popup = true
      }
      
    },
    ContactPopup(){
      this.contact_popup = false
      this.$emit('update')
    },
    changeInput(data){
      this.listNew[data.index].val = data.value
      this.listNew[data.index].type_id = data.type
      this.listNew[data.index].id = data.index
      this.listNew[data.index].tech_name = data.tach
    },

    async saveContact(){
      var dataPerson ={
        name: this.name,
        position: this.post,
        card: this.card,
        client_id: this.id,
        owner_type: this.info.modPerson
      }


      if(this.info.mod == 'update'){
        if(this.name){
          dataPerson.id = this.dataPerson[this.info.index].id
          var res = await this.$store.dispatch('updateContactsPerson', dataPerson)
        }
        if(this.listNew.length == 0){
          this.$emit('closeContact')
        }

        var numb = 0
        var app = this
        this.listNew.forEach(async function(item, index){
          var data = {
            client_id: app.id,
            type: +item.type_id,
            value: item.val,
            owner_type: app.info.modPerson
          }
          if(app.name){
            data.person_id = app.dataPerson[app.info.index].id
          }
          var res = await app.$store.dispatch('addContacts', data)
          if(res.data.status == 'error'){
            numb++
            app.error = true
          } else{
            app.listNew.splice(index, 1);
            if(app.listNew.length == 0 && numb == 0){
              app.error = false;
              app.$emit('closeContact')
            } else{
              app.$emit('update')
            }
          }
        })
        numb = 0



      } else{
        if(this.name){
          if(this.error){
            dataPerson.id = this.newIdPerson
            var person = await this.$store.dispatch('updateContactsPerson', dataPerson)
          } else{
            var person = await this.$store.dispatch('addContactsPerson', dataPerson)
            this.newIdPerson = person.data.id
          }
        }
        var numb = 0;
        if(this.listNew.length !== 0){
          var app = this
          this.listNew.forEach(async function(item, index){
            var data = {
              client_id: app.id,
              type: +item.type_id,
              value: item.val,
              owner_type: app.info.modPerson,
            }
            if(person){
              data.person_id = person.data.id
            }
            if(app.error){
              data.person_id = app.newIdPerson
            }
            var res = await app.$store.dispatch('addContacts', data)
            
            if(res.data.status == 'error'){
              numb++
              app.error = true;
            } else{
              app.listContact.push(app.listNew[index]);
              app.listNew.splice(index, 1);
              if(app.listNew.length == 0 && numb == 0){
                app.error = false;
                app.$emit('closeContact')
              } else{
                app.$emit('update')
              }
            }
          })
          numb = 0
        } else{
          this.$emit('closeContact')
        }
      }
    },



    addContacts(){
      this.listNew.push({type_id: "1", val: "", id: this.listNew.length})
    },

  },



  components:{
    ContactPopup, Contact
  }


}
</script>