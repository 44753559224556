<template>
  <transition name="fade">
    <div v-if="popup">
      <div class="pop-up-bg" @click.prevent="$emit('close')"></div>
      <div class="pop-up">
        <div v-if="!addcity">
          <div v-if="filter.flag">
            <a class="pop-up__close icon icon-close" @click.prevent="$emit('close')"></a>
            <h2>{{$root.langText.changes.city}}</h2>
            <SelectedFilters v-if="selectFilters.length !== 0" :data="selectFilters" @sendChange="filterChange = $event"/>
            <Search :isSearch='false' :isActiveFilter="btnFilter" @filter="filter.flag = !filter.flag" @inputchange="search" v-if="citys !== null"/>
            <p class="list-not" v-if="listNot">{{$root.langText.notFound}}</p>
            <ul class="pop-up__list">
              <li
                v-for="(city, index) in citys"
                :key="index"
                :data-id="city.id"
                @click.prevent="$emit('close', {id: city.id, name: city.name, img: (city.country_code ? city.country_code.toLowerCase() : '')})"
              >
                <div class="pop-up__list__row">
                  <img v-if="city.country_code" :src="'https://democrm-sss.soft-pro.biz/img/flags/' + (city.country_code ? city.country_code.toLowerCase() : '') + '.svg'" alt=""> 
                  {{city.name}}
                </div>
                <div class="decor-line"></div>
              </li>
            </ul>
            <div style="position: relative; height: 73px">
              <Pagination v-if="quantityList && quantityList > params.limit" @pagination="pagination" :dataList="{quantityList, params}"/>
              <a v-if="$root.add.can_add_city" class="btn btn-transparent" style="position:absolute;top: 20px; padding: 6px 20px;" @click.prevent="addcity = !addcity">{{$root.langText.btnAdd}}<div class="icon-plus"></div></a>
            </div>
          </div>
        </div>
        <div v-if="addcity">
          <h2>{{$root.langText.input.city}}</h2>
          <div class="form__input">
            <input 
              type="text" 
              v-model="name" 
            >
            <span>{{$root.langText.input.name}}</span>
          </div>
          <Autocomplete
            :data="{name: $root.langText.directory.country, mod: 'countries', key: 'countries', input: countries, inputId: countries_id}" 
            @result="autocompleteResult" 
          />
          <p v-if="emty" style="color: red; padding: 0px 0 15px">{{$root.langText.changes.enterData}}</p>
          <div class="pop-up__btn">
            <a class="btn btn-secondary" @click.prevent="addcity = !addcity">{{$root.langText.cancel}}</a>
            <a class="btn btn-primary" @click.prevent="save()">{{$root.langText.save}}</a>
          </div>

        </div>

        <Filters @filter="filters" :isFilter="false" :filterChange="filterChange" :filter="filter"/>
      </div>
    </div>
  </transition>
</template>


<style lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }
  .pop-up__list li img{
    height: 15px;
    margin-right: 10px;
  }

</style>


<script>
import Pagination from "@/components/app/Pagination"
import Search from "@/components/app/Search"
import SelectedFilters from "@/components/app/SelectedFilters"

export default {
  props: ['popup'],

  data:() => ({
    addcity: false,
    countries: '',
    countries_id: '',
    name: '',
    emty: false,
    citys: [],
    quantityList: '',
    //pagination
    params: {
      start: 0, 
      limit: 20,
    },
    filter: {
      flag: true,
      mod: 'city',
      screen: true
    },
    arrFilters: '',
    listNot: false,
    btnFilter: false,

    searchData: '',
    filtersData: '',
    filterChange: '',
    selectFilters: '',
    
  }),
  async mounted() {
    //base
    const city = await this.$store.dispatch('getCity', this.params);
    this.citys = city.data.list;
    this.quantityList = city.data.total_num
  },
  methods: {
    autocompleteResult(data){
      this[data.input] = data.name
      this[data.input + '_id'] = data.id
    },

    async save(){
      var data = {
        country_id: this.countries_id,
        name: this.name,
      }
      if(this.countries_id && this.name){
        var res = await this.$store.dispatch('addCity', data)
        this.addcity = !this.addcity
        this.countries_id = ''
        this.name = ''
        this.countries = ''

        const city = await this.$store.dispatch('getCity', this.params);
        this.citys = city.data.list;
        this.quantityList = city.data.total_num
        this.emty = false
      } else{
        this.emty = true
      }
    },



    //function filtration
    async reloadFilters(mod){
      if(mod !== 'pagination'){
        this.params.start = 0
      }
      this.arrFilters = this.searchData + "&" + this.filtersData
      var arrFilters = await this.$store.dispatch('postFiltersCity', {params: this.params, data: this.arrFilters})
      this.citys = arrFilters.data.list
      this.quantityList = arrFilters.data.total_num
      if(this.quantityList == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    },


    //filters
    async filters(data){
      this.filter.flag = true
      if(data == undefined){
        this.filtersData = ''
        this.btnFilter = false
      } else{
        if(data.filter == ''){
          this.btnFilter = false
        } else{
          this.btnFilter = true
        }
        this.selectFilters = data.dataIsFilter
        this.filtersData = data.filter
      }

      if(data.load !== true){
        this.reloadFilters('filters');
      }
      
    },

    //search
    async search(data) {
      if(data !== undefined && data.load !== true){
        this.searchData = data.search
      }
      if(data.load !== true){
        this.reloadFilters('search');
      }
    },

    //paginations
    async pagination(data){
      this.params.start = data.start
      if(data.load !== true){
        this.reloadFilters('pagination')
      }
    }

  },
  components:{
    Pagination, Search, Filters: () => import('@/components/app/Filters.vue'), SelectedFilters, Autocomplete: () => import('@/components/app/Autocomplete.vue')
  }
  
}
</script>